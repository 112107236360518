<script>
export default {
    name: 'CardsPage',
};
</script>

<script setup>
import { onBeforeMount, ref } from 'vue';
import i18n from '@/plugins/i18n.js';
import CrmLogo from '@/components/crm-logo.vue';
import BlockContainer from '@/components/block-container';
import notify from '@/components/base-components/components/notification/notify.js';

const props = defineProps({
    title: {
        type:     String,
        required: true,
    },
    fetch: {
        type:     Function,
        required: true,
    },
});

const loading = ref(false);
const cards = ref([]);

onBeforeMount(async () => {
    loading.value = true;

    try {
        cards.value = await props.fetch();
    } catch {
        notify.error({
            title: i18n.t('tmp An error occurred'),
        });
    } finally {
        loading.value = false;
    }
});

function goToUrl(url) {
    window.open(url, '_blank');
}
</script>

<template>
    <div v-loader.immediate="loading" class="flex min-h-full w-full flex-1 flex-col items-center py-6">
        <div class="mx-auto flex w-full max-w-7xl flex-col items-center px-4 sm:px-6 md:px-8 lg:space-y-10">
            <div class="hidden text-center text-3xl font-bold lg:mt-10 lg:block">
                {{ title }}
            </div>
            <div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
                <block-container
                    v-for="card in cards"
                    :key="card.title"
                    content-class="flex flex-col justify-between space-y-3"
                >
                    <div class="space-y-2">
                        <div class="text-lg font-black">
                            {{ card.title }}
                        </div>
                        <div>
                            {{ card.message }}
                        </div>
                    </div>

                    <div class="flex items-end justify-between gap-x-3">
                        <base-button
                            class="self-start"
                            type="primary"
                            round
                            @click="goToUrl(card.url)"
                        >
                            {{ $t('read more') }}
                        </base-button>

                        <crm-logo v-if="card.platform" :crm="card.platform" size="sm" />
                    </div>
                </block-container>
            </div>
        </div>
    </div>
</template>
