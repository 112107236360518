<script>
export default {
    name: 'AppSidebarButton',
};
</script>

<script setup>
import { computed } from 'vue';
import { useStore } from '@/store/index.js';

const store = useStore();

const collapsed = computed(() => store.state.sidebar.collapsed);

function toggleSidebar() {
    store.dispatch('sidebar/TOGGLE');
}
</script>

<template>
    <div
        class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full text-white/15 transition-all ease-in-out bg-secondary-950 hover:text-white/75 active:bg-secondary-800 active:ring-secondary-800"
        @click="toggleSidebar"
    >
        <svg v-if="collapsed" width="512" height="512" viewBox="0 0 512 512" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M280.346 333.602C272.677 325.933 272.677 313.5 280.346 305.832L325.208 260.97C327.16 259.017 327.16 255.851 325.208 253.899L280.115 208.806C272.32 201.011 272.32 188.371 280.115 180.576C287.911 172.78 300.55 172.78 308.346 180.576L370.601 242.831C378.412 250.642 378.412 263.305 370.601 271.116L308.115 333.602C300.447 341.27 288.014 341.27 280.346 333.602Z"
            />
            <path
                fill-rule="evenodd" clip-rule="evenodd"
                d="M146 0C65.3664 0 0 65.3664 0 146V366C0 446.634 65.3664 512 146 512H366C446.634 512 512 446.634 512 366V146C512 65.3664 446.634 0 366 0H146ZM366 39H180V473H366C425.094 473 473 425.094 473 366V146C473 86.9055 425.094 39 366 39ZM39 146C39 88.2448 84.7588 41.1771 142 39.0734V472.927C84.7588 470.823 39 423.755 39 366V146Z"
            />
        </svg>
        <svg v-else width="512" height="512" viewBox="0 0 512 512" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M370.382 180.479C378.051 188.148 378.051 200.581 370.382 208.249L325.52 253.111C323.568 255.064 323.568 258.23 325.52 260.182L370.613 305.275C378.408 313.07 378.408 325.71 370.613 333.505C362.817 341.301 350.178 341.301 342.382 333.505L280.127 271.25C272.316 263.439 272.316 250.776 280.127 242.965L342.613 180.479C350.281 172.811 362.714 172.811 370.382 180.479Z"
            />
            <path
                fill-rule="evenodd" clip-rule="evenodd"
                d="M146 0C65.3664 0 0 65.3664 0 146V366C0 446.634 65.3664 512 146 512H366C446.634 512 512 446.634 512 366V146C512 65.3664 446.634 0 366 0H146ZM366 39H180V473H366C425.094 473 473 425.094 473 366V146C473 86.9055 425.094 39 366 39ZM39 146C39 88.2448 84.7588 41.1771 142 39.0734V472.927C84.7588 470.823 39 423.755 39 366V146Z"
            />
        </svg>
    </div>
</template>
