<script>
export default {
    name: 'AppSidebar',
};
</script>

<script setup>
import Vue, { ref, computed } from 'vue';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { useStore } from '@/store/index.js';
import i18n from '@/plugins/i18n.js';
import SidebarIcon from '@/assets/img/logo/sidebar-icon.svg';
import SidebarLogo from '@/assets/img/logo/sidebar.svg';
import SidebarBackup from '@/components/app/sidebar/section/backup.vue';
import SidebarMigration from '@/components/app/sidebar/section/migration.vue';
import SidebarSettings from '@/components/app/sidebar/section/settings.vue';
import { HELP_AND_INFORMATION, POLICY_AND_AGREEMENTS, PRODUCTS } from '@/store/modules/acl-access/constants.js';

const store = useStore();

store.dispatch('sidebar/INIT');

const collapsed = computed(() => store.state.sidebar.collapsed);

function toggleSidebar() {
    store.dispatch('sidebar/TOGGLE');
}

const breakpoints = useBreakpoints(breakpointsTailwind);

function sectionNavigate() {
    if (collapsed.value || breakpoints.greaterOrEqual(breakpoints.md)) return;

    toggleSidebar();
}

const showIcon = computed(() => {
    const order = breakpoints.current().value;

    if (order.indexOf(breakpoints.active().value) < order.indexOf(breakpoints.md)) return false;

    return collapsed.value;
});

const { value: coBrandingDomain } = window.config?.cobranding ?? {};

function getImagePath(name, def) {
    return coBrandingDomain ? `${import.meta.env.BASE_URL}img/cobranding/${coBrandingDomain}/${name}` : def;
}

const logo = computed(() => getImagePath('sidebar.svg', SidebarLogo));
const icon = computed(() => getImagePath('sidebar-icon.svg', SidebarIcon));

const isBackupAvailable = computed(() => store.getters['aclAccess/isGranted'](PRODUCTS.BACKUP));
const isMigrationAvailable = computed(() => store.getters['aclAccess/isGranted'](PRODUCTS.MIGRATION));

function getInjectedModulesSidebars(key) {
    const components = [];

    Vue.prototype.$appModules.forEach((module, name) => {
        const sidebarValue = module.sidebar?.[key];

        if (!sidebarValue) return;

        if (typeof sidebarValue !== 'object') {
            components.push({
                name,
                component: sidebarValue,
            });

            return;
        }

        Object.keys(sidebarValue).forEach((sidebarName) => {
            if (store.getters['aclAccess/isGranted'](sidebarName) === false) return;

            components.push({
                name:      sidebarName,
                component: sidebarValue[sidebarName],
            });
        });
    });

    return components;
}

const mainSections = ref([]);
const additionalSections = ref([]);

function initSidebarSections() {
    mainSections.value = getInjectedModulesSidebars('main');
    additionalSections.value = getInjectedModulesSidebars('additional');
}

initSidebarSections();

window.emitter.on('app-modules', () => {
    initSidebarSections();
});

const helpButtons = computed(() => [
    {
        acl:   HELP_AND_INFORMATION,
        icon:  {
            name:   'question-circle',
            prefix: 'far',
        },
        label: i18n.t('header breadcrumb help'),
        route: {
            name: 'help',
        },
    },
    {
        acl:   POLICY_AND_AGREEMENTS,
        icon:  {
            name:   'file-signature',
            prefix: 'fas',
        },
        label: i18n.t('header breadcrumb policy'),
        route: {
            name: 'policy',
        },
    },
].filter(({ acl }) => store.getters['aclAccess/isGranted'](acl)));
</script>

<template>
    <div>
        <div
            class="fixed flex h-full shrink-0 bg-gradient-to-b shadow-2xl transition-all duration-500 ease-in-out from-secondary-950 to-secondary-800 z-120 md:relative md:bg-none md:shadow-none"
            :class="collapsed ? 'w-64 -translate-x-full md:w-14 md:translate-x-0' : 'w-64 translate-x-0'"
        >
            <div class="relative flex w-full flex-col">
                <div class="relative flex h-16 items-center justify-center">
                    <div
                        class="flex h-full items-center justify-center overflow-hidden transition-all duration-500 ease-in-out [direction:rtl]"
                        :class="collapsed ? 'md:w-10' : 'w-full'"
                    >
                        <div
                            class="flex items-center"
                            :data-test="dataTest('logo')"
                        >
                            <router-link
                                class="flex shrink-0 items-center justify-center"
                                to="/"
                            >
                                <div class="relative flex items-center justify-center">
                                    <template v-if="coBrandingDomain">
                                        <img
                                            v-if="showIcon"
                                            :src="icon"
                                            class="h-8"
                                            alt="MigrateMyCRM"
                                        >
                                        <img
                                            v-else
                                            :src="logo"
                                            class="h-10 w-52"
                                            alt="MigrateMyCRM"
                                        >
                                    </template>
                                    <template v-else>
                                        <img
                                            :src="icon"
                                            class="absolute h-8"
                                            :class="{
                                                'duration-500 transition-transform opacity-100 translate-x-[84px]': showIcon,
                                                'opacity-0 translate-x-[38px] -translate-y-[10px] scale-[0.63] [transition:opacity_50ms_450ms_ease-in-out,transform_500ms_ease-in-out]': !showIcon,
                                            }"
                                            alt="MigrateMyCRM"
                                        >
                                        <img
                                            :src="logo"
                                            class="h-10 w-52 transition-opacity duration-300 ease-in-out"
                                            :class="showIcon ? 'opacity-0' : 'opacity-100 delay-300'"
                                            alt="MigrateMyCRM"
                                        >
                                    </template>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <base-scroll
                    class="flex flex-1 flex-col"
                    wrap-class="flex flex-1 flex-col"
                    view-class="flex flex-1 flex-col justify-between p-2 text-gray-300 space-y-2"
                >
                    <div class="-mx-2 flex shrink-0 flex-col space-y-1">
                        <sidebar-migration
                            v-if="isMigrationAvailable"
                            class="px-2"
                            @navigate="sectionNavigate"
                        />
                        <sidebar-backup
                            v-if="isBackupAvailable"
                            class="px-2"
                            @navigate="sectionNavigate"
                        />
                        <component
                            v-for="section in mainSections"
                            :key="section.name"
                            :is="section.component"
                            class="px-2"
                            @navigate="sectionNavigate"
                        />
                    </div>
                    <div class="flex shrink-0 flex-col gap-y-1">
                        <component
                            v-for="section in additionalSections"
                            :key="section.name"
                            :is="section.component"
                            @navigate="sectionNavigate"
                        />
                        <sidebar-settings
                            @navigate="sectionNavigate"
                        />
                        <div v-if="helpButtons.length" class="flex flex-col border-t border-white/20 pt-1 space-y-1">
                            <router-link
                                v-for="(helpButton, index) in helpButtons"
                                v-slot="{ href, navigate, isActive }"
                                :key="index"
                                :to="helpButton.route"
                                custom
                                @click.native="sectionNavigate"
                            >
                                <a
                                    :href="href"
                                    class="flex w-full rounded-md font-semibold uppercase tracking-wide hover:bg-black/30 hover:text-white focus:text-white focus:outline-none"
                                    :class="{
                                        'text-white bg-black/40 shadow-inner shadow-black/30': isActive,
                                        'text-gray-300': !isActive,
                                    }"
                                    :title="collapsed ? helpButton.label : undefined"
                                    @click="navigate"
                                >
                                    <div
                                        class="flex w-full items-center py-2 duration-500 space-x-3 transition-spacing"
                                        :class="collapsed ? 'px-2' : 'px-4'"
                                    >
                                        <div class="flex h-5 w-6 shrink-0 items-center justify-center">
                                            <base-icon
                                                class="text-xl"
                                                :name="helpButton.icon.name"
                                                :prefix="helpButton.icon.prefix"
                                                fixed-width
                                            />
                                        </div>
                                        <span class="overflow-hidden whitespace-nowrap text-xs leading-none">
                                            {{ helpButton.label }}
                                        </span>
                                    </div>
                                </a>
                            </router-link>
                        </div>
                    </div>
                </base-scroll>
            </div>
        </div>
        <div
            class="fixed h-screen w-screen bg-gray-800 transition-all duration-300 ease-in-out z-110 md:hidden"
            :class="collapsed ? 'bg-opacity-0 pointer-events-none' : 'bg-opacity-50 backdrop-blur'"
            @click="toggleSidebar"
        />
    </div>
</template>
