<script>
export default {
    name: 'PolicyPage',
};
</script>

<script setup>
import Vue from 'vue';
import CardsPage from '@/pages/components/cards-page.vue';
</script>

<template>
    <cards-page
        :title="$t('header breadcrumb policy')"
        :fetch="() => Vue.prototype.$http.get('/policy')"
    />
</template>
